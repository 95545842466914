<template>
  <div id="bbForm">
    <van-form ref="form" class="form" style="margin-bottom:65px">
      <!-- 基本信息 -->
      <van-cell-group title="基本信息">
        <custom-select ref="xn" required v-model="form.xn" placeholder="请选择学年" label="学年" :columns="dict.xj_nj" />
        <custom-select ref="xq" required v-model="form.xq" placeholder="请选择学期" label="学期" :columns="dict.xj_xqgl" />
        <van-field v-model="form.score" placeholder="请选择分值" label="分值" :required="true" :rules="[{ required: true  } ]">
          <template #input>
            <van-stepper v-model="form.score" min="-99" max="99" />
          </template>
        </van-field>
      </van-cell-group>

      <!-- 负责学生-->
      <van-cell-group title="学生信息">
        <van-field v-model="form.stuName" label="姓名" readonly @click="familyAdd" placeholder="点击选择学生" :required="true" :rules="[{ required: true  } ]" />
        <van-field v-model="form.stuNo" label="学号" readonly :required="true" :rules="[{ required: true  } ]" />
        <van-field v-model="form.facultyName" label="院系" readonly />
        <van-field v-model="form.specName" label="专业" readonly />
        <van-field v-model="form.className" label="班级" readonly />
        <van-field v-model="form.explain" rows="3" :required="true" :rules="[{ required: true  } ]" class="textarea-style" placeholder="请填写申请说明" arrow-direction="" label="申请说明" type="textarea" maxlength="300" />
      </van-cell-group>
      <!-- 附件信息 -->
      <!-- <van-cell-group title="附件">
        <file-upload v-if="refsh" accept=".doc,.docx,.pdf,.xls,.xlsx,.png,.gif,.jpeg" v-model="fileList" miz="STU_PUNISH_RESOLVE" biz="storage" :multiple="1" v-on:uploadResponse="uploadResponse" list-type="list" :auto-upload="true" :max-size="1">
          <span slot="tip" class="upload-tips">
            支持上传任意文件,文件最大1M
          </span>
        </file-upload>
      </van-cell-group> -->
      <div class="submit-button shadow-style">
        <div>
          <van-button round block type="primary" :loading="loading" @click="doSubmit()">提 交</van-button>
        </div>
      </div>
    </van-form>
    <!-- 负责学生 -->
    <van-popup v-model="familyInfoShow" @close="familyclose" closeable position="bottom" :style="{ height: '80%', backgroundColor:'#F5F5F5' }">
      <div class="wj-title">学生选择</div>
      <van-search class="wj-search" v-model="search" @input="onSearchHandle" placeholder="请输入学生学号或姓名检索" />
      <div class="wj-body">
        <van-cell v-for="(row,index) in stuData" :key="index" class="stu-card">
          <template #title>
            <span style="font-size:15px;font-weight:bold"> {{row. stuName +"  " +row. stuNo}}</span>
          </template>
          <template #label>
            <span style="font-size:14px;">{{ row.sqecName +'-'+  row.className}}</span>
          </template>
          <template #right-icon>
            <van-button class="table-edit-btn" @click="selectRow(row)" plain round size="small" type="primary">选择</van-button>
          </template>
        </van-cell>
      </div>
    </van-popup>
  </div>
</template>
<script>
import crudEvaluationAddScore from '@/api/modules/evaluation/evaluationAddScore'
import { getStuinfoNotPageSmall } from '@/api/modules/xj/stuInfo'
import fileUpload from "@/components/FileUpload"
import { validCustom } from "@/utils/index"
import { getTermYear, getTerm } from '@/utils/index'
import crudEvaluationConfig from '@/api/modules/evaluation/evaluationConfig'


export default {
  name: "EvaluationActivityForm",
  dicts: ['evaluation_activity_type', 'xj_nj', 'xj_xqgl', 'xj_shzt'],
  components: { fileUpload },
  data() {
    return {
      form: {
        shr: null, xn: null, xq: null, id: null, violateNum: null,
        stuInfo: [],
        violateNum: null,
        applayType: null,
        applayType2: null,
        applayType3: null,
        explain: null,
        fileId: null,
        stuNo: null,
        stuName: null,
        grade: null,
        facultyNo: null,
        facultyName: null,
        specNo: null,
        specName: null,
        classNo: null,
        className: null,
        score: null,
        type: 2
      },
      option1: [],
      option2: [],
      option3: [],
      option4: [],
      fresh: true,
      byNumShow: false,
      activeNames: [0],
      activeNames2: [0],
      isFamilyAdd: false,
      familyInfoShow: false,
      editIndex: 0,
      loading: false,
      auditByInfo: [],
      search: "",
      stuData: [],
      refsh: true,
      fileList: [],
      byNumShow: false,
      search: "",
      customRuleFileds: ["xn", "xq"]
    }
  },
  watch: {
    "dict.xj_xqgl": {
      deep: false,
      handler(newval, oldval) {
        if (newval.length > 0) {
          this.getTermAndYear(newval);
        }
      },
    },
  },
  async created() {
    // 或者审核人最小权限
    crudEvaluationConfig.getAuditUserPage().then((res) => {
      this.auditByInfo = res
    })
    crudEvaluationConfig.getConfigNoPage({ type: 2 }).then((res) => {
      if (res) {
        this.option1 = res.map((e) => {
          e['childs'] = e.children
          e.children = undefined
          return e;
        });
      }
    })
    if (this.$route.query && this.$route.query.id) {
      await crudEvaluationAddScore.findById(this.$route.query.id).then((res) => {
        this.form = { ...res }
        if (res.fileId) {
          this.fileList = [res.fileId]
        }
        this.refsh = false
        this.$nextTick((e) => {
          this.refsh = true
        });
      })
    } else {
      this.getTermAndYear()
    }
  },
  methods: {
    selectClickHandle(value, val) {
      this.option2 = []
      this.option3 = []
      this.option4 = []
      if (val.byNum) {
        this.form.violateNum = 1
        this.byNumShow = true;
      } else {
        this.byNumShow = false;
      }
      if (val.childs && val.childs.length > 0) {
        this.option2 = val.childs.map((e) => {
          e['childs'] = e.children
          e.children = undefined
          return e;
        });
      }
      this.form.applayType2 = null
      this.form.applayType3 = null
      // 赋值分数
      this.score = parseInt(val.value)
      this.form.score = parseInt(val.value)
    },
    selectClickHandle2(value, val) {
      if (val.childs && val.childs.length > 0) {
        this.option3 = val.childs.map((e) => {
          e['childs'] = e.children
          e.children = undefined
          return e;
        });
      }
      this.form.score = parseInt(val.value)
      this.form.applayType3 = null
    },
    selectClickHandle3(value, val) {
      this.score = parseInt(val.value)
      this.form.score = this.score
    },
    selectClickHandle4(value, val) {
      // this.form.shrxm = val.name
    },
    // 计算分数
    changeOption4(val) {
      if (!this.isDetail && val) {
        this.form.score = parseInt(val) * parseInt(this.score)
      }
    },
    getTermAndYear(xj_xqgl) {
      if (xj_xqgl && xj_xqgl.length > 0) {
        let termYear = getTermYear(
          xj_xqgl,
          (new Date().getMonth() + 1).toString()
        )
        let term = getTerm(
          xj_xqgl,
          (new Date().getMonth() + 1).toString()
        )
        this.form.xn = termYear
        this.form.xq = term.xqCode
      }
    },
    selectRow(item) {
      this.form.stuNo = item.stuNo
      this.form.stuName = item.stuName
      this.form.className = item.className
      this.form.classNo = item.classNo
      this.form.facultyName = item.collName
      this.form.facultyNo = item.collNo
      this.form.specName = item.sqecName
      this.form.specNo = item.sqecCode
      this.form.grade = item.gradeNo
      this.form.conslrStaffNo = item.conslrStaffNo
      this.form.conslrStaffName = item.conslrStaffName
      this.familyclose()
    },
    uploadResponse(fileIds) {
      if (fileIds && fileIds[0]) {
        this.form.fileId = fileIds[0]
      }
    },
    onSearchHandle(queryString) {
      getStuinfoNotPageSmall({ stu: queryString }).then((stulist) => {
        if (stulist && stulist.length) {
          this.stuData = stulist
        } else {
          this.stuData = []
        }
      })
    },
    familyAdd() {
      this.familyInfoShow = true;
    },
    familyclose() {
      this.familyInfoShow = false;
      this.stuData = []
      this.search = ""
    },
    doAdd() {
      this.loading = false;
      crudEvaluationAddScore.add(this.form)
        .then((res) => {
          this.loading = false;
          this.$notify({
            type: "success",
            message: "操作成功",
            duration: 2000,
          });
          this.cancel();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    doEdit() {
      this.loading = false;
      crudEvaluationAddScore.edit(this.form)
        .then((res) => {
          this.loading = false;
          this.$notify({
            message: "操作成功",
            type: "success",
            duration: 2000,
          });
          this.cancel();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    cancel() {
      this.form = {
        shr: null,
        xn: null,
        xq: null,
        id: null,
        violateNum: null,
        stuInfo: [],
        violateNum: null,
        applayType: null,
        applayType2: null,
        applayType3: null,
        explain: null,
        fileId: null,
        stuNo: null,
        stuName: null,
        grade: null,
        facultyNo: null,
        facultyName: null,
        specNo: null,
        specName: null,
        classNo: null,
        className: null,
        score: null,
        type: 1
      }
      this.$router.go(-1)
    },
    doSubmit() {
      this.$refs.form.validate().then(
        (res) => {
          if (validCustom(this.customRuleFileds, this)) {
            // 验证通过数据提交
            this.$dialog
              .confirm({
                title: "提示",
                message: "你确定要进行提交操作吗？",
              })
              .then(() => {
                this.loading = true;
                if (this.$route.query && this.$route.query.id) {
                  this.doEdit();
                } else {
                  this.doAdd();
                }
              })
              .catch(() => {
                // on cancel
              });
          } else {
            this.$notify({
              type: "warning",
              message: "有提交项未填写！",
              duration: 1500,
            });
          }
        },
        (err) => {
          validCustom(this.customRuleFileds, this);
          this.$notify({
            type: "warning",
            message: "有提交项未填写！",
            duration: 1500,
          });
        }
      );
    },
  },
}
</script>

<style lang="scss" scoped>
#bbForm {
  .form {
    ::v-deep.radio-other-info {
      .van-cell {
        .van-field__label {
          width: 11.2em;
        }
      }
    }

    .van-field__label {
      width: 110px;
    }
    .van-cell {
      font-size: 15px;
      line-height: 32px;
      height: 50px;
    }
    .textarea-style {
      height: 100%;
    }
  }

  .wj-title {
    text-align: center;
    line-height: 55px;
    font-size: 17px;
    font-weight: bold;
    height: 40px;
    background-color: #fff;
  }
  .wj-search {
  }
  .stu-card {
    margin-top: 18px;
  }
  .wj-body {
    overflow-y: scroll;
    height: calc(80vh - 100px);
    padding: 0 18px;
  }
}
</style>
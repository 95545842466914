import request from '@/utils/request'

export function add(data) {
    return request({
        url: 'api/evaluation/addScore',
        method: 'post',
        data
    })
}

export function del(ids) {
    return request({
        url: 'api/evaluation/addScore/',
        method: 'delete',
        data: ids
    })
}

export function edit(data) {
    return request({
        url: 'api/evaluation/addScore',
        method: 'put',
        data
    })
}


export function fileDel(data) {
    return request({
        url: 'api/evaluation/addScore/fileDel',
        method: 'put',
        data
    })
}

export function findById(id) {
    return request({
        url: 'api/evaluation/addScore/byId/' + id,
        method: 'get',
    })
}


export default { add, edit, del, fileDel, findById }
